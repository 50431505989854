<template>
  <div>
    <v-skeleton-loader
      :loading="initialLoading"
      type="heading, card, table-tbody"
    >
      <v-card-title class="d-flex align-center justify-space-between">
        <v-layout
          d-flex
          align-center
        >
          <v-btn
            v-show="['stoped','stopped', 'done'].includes(details.status)"
            color="primary"
            @click="() => openRetryActionModal({id:details.id,account_delay:details.account_delay,
                                                batch_delay: details.batch_delay,
                                                batch_size: details.batch_size,})"
          >
            <v-icon left>
              {{ icons.mdiReplay }}
            </v-icon> Retry
          </v-btn>
          <v-btn
            v-show="['in_progress', 'pending'].includes(details.status)"
            color="primary"
            @click="() => tryStopAction(details.id)"
          >
            <v-icon left>
              {{ icons.mdiStop }}
            </v-icon> Stop
          </v-btn>
          <v-btn
            v-if="details.project_id"
            :to="{name: 'discordProjectDetails', params: {id: details.project_id}}"
            class="ml-4"
          >
            <v-icon left>
              {{ icons.mdiOpenInNew }}
            </v-icon>
            Go to project
          </v-btn>
          <v-spacer />

          <v-btn
            v-show="!loading"
            icon
            @click="() => fetchActionDetails()"
          >
            <v-icon>
              {{ icons.mdiRefresh }}
            </v-icon>
          </v-btn>
          <v-progress-circular
            :value="progress"
            :size="50"
            :rotate="270"
            :indeterminate="loading"
            color="accent"
          >
            <span v-show="!loading">{{ countdown }}</span>
          </v-progress-circular>
        </v-layout>
      </v-card-title>
      <v-layout column>
        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col md="6">
                <v-row>
                  <v-col
                    lg="2"
                    md="4"
                  >
                    Type:
                  </v-col>
                  <v-col>
                    {{ details.type }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    lg="2"
                    md="4"
                  >
                    Status:
                  </v-col>
                  <v-col>
                    {{ details.status }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    lg="2"
                    md="4"
                  >
                    Created at:
                  </v-col>
                  <v-col>
                    {{ createdTime }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="6">
                <v-row>
                  <v-col
                    lg="3"
                    md="5"
                  >
                    Account Delay:
                  </v-col>
                  <v-col>
                    {{ details.account_delay }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    lg="3"
                    md="5"
                  >
                    Batch Delay:
                  </v-col>
                  <v-col>
                    {{ details.batch_delay }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    lg="3"
                    md="5"
                  >
                    Batch Size:
                  </v-col>
                  <v-col>
                    {{ details.batch_size }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                Statuses:
                <div class="statuses">
                  <v-tooltip
                    v-for="(count, key) in details.count_by_status"
                    :key="`${count}${key}`"
                    top
                  >
                    <template v-slot:activator="{on, attrs}">
                      <div
                        v-show="count"
                        v-bind="attrs"
                        :class="statusColors[key]"
                        class="mr-1 status text-center"
                        :style="{
                          width:`${count / totalStatus(details.count_by_status) * 100}%`
                        }"
                        v-on="on"
                      >
                        {{ count }}
                      </div>
                    </template>
                    <span>{{ key }}: {{ count }}</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>
            Accounts
            <v-spacer />
            <TableSearch
              v-model="tableOptions.search"

              :show-filters.sync="showFilters"

              :check-filter-toggle="checkFilterToggle"
            />
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-data-table
              hide-default-footer
              :mobile-breakpoint="0"
              item-key="account_id"
              loading-text="Loading accounts ..."
              :server-items-length="total"
              :loading="loading"
              :items="details.accounts"
              :headers="accountHeaders"
              show-expand
              :options.sync="tableOptions"
              :footer-props="{
                'items-per-page-options': pages,
                'show-first-last-page': true,
                'show-current-page': true,
              }"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  class="pa-4"
                  :colspan="12"
                >
                  <v-row>
                    <v-col md="2">
                      Discord Account Id:
                    </v-col>
                    <v-col> {{ item.discord_user_id }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2">
                      Proxy:
                    </v-col>
                    <v-col> {{ (item.proxy && item.proxy.value) || 'no proxy' }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2">
                      Proxy name:
                    </v-col>
                    <v-col> {{ (item.proxy && item.proxy.name) || 'no proxy' }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2">
                      Login:
                    </v-col>
                    <v-col> {{ item.login }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2">
                      Password:
                    </v-col>
                    <v-col>
                      {{ item.password }}
                      <CopyTextBtn :text="item.password" />
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col md="2">
                      Token:
                    </v-col>
                    <v-col>
                      {{ item.token }}
                      <CopyTextBtn :text="item.token" />
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <v-col md="2">
                      Seller:
                    </v-col>
                    <v-col> {{ item.seller || 'no seller' }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col md="2">
                      Error:
                    </v-col>
                    <v-col> {{ item.error.length>0?item.error[0]:"No errors" }}</v-col>
                  </v-row>
                </td>
              </template>
              <template
                v-slot:top
              >
                <v-layout
                  v-show="showFilters"
                  class="pa-4"
                >
                  <v-row>
                    <v-col cols="3">
                      <v-select
                        v-model="tableOptions.status"
                        label="Status"
                        placeholder="Select status"
                        hide-details
                        clearable
                        :items="actionDetailsStatuses"
                        class="pa-0 ma-0"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-layout>
              </template>
              <template v-slot:item.status="{item}">
                <div class="d-flex justify-end">
                  <v-chip
                    :color="StatusesColorMap[item.status].color"
                  >
                    {{ StatusesColorMap[item.status].text }}
                  </v-chip>
                  <v-tooltip
                    close-delay="1500"
                    top
                    :disabled="isExtEnabled==='true'"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-btn
                        v-bind="attrs"
                        icon
                        class="mr-4"
                        :class="{'disabled--tooltip':!isExtEnabled||!item.token}"
                        v-on="on"
                        @click="() =>{ openDiscordAccount(item.token)}"
                      >
                        <v-icon>{{ icons.mdiDiscord }}</v-icon>
                      </v-btn>
                    </template>
                    <span>Install <a @click="()=>{$router.push({name:'home'})}">chrome Extension</a> to open this account in browser</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.username="{ item, value }">
                <span dir="ltr">{{ value }}</span>
                <span v-show="item.discriminator">#{{ item.discriminator }}</span>
              </template>
              <template v-slot:footer="{ props, on }">
                <TableFooter
                  v-bind="props"
                  v-on="on"
                />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-layout>
    </v-skeleton-loader>

    <RetryActionModal
      ref="retryActionModal"
      @updated="(value) => details = {...details, ...value}"
    />
  </div>
</template>

<script>
import {
  mdiChevronLeft,
  mdiCheckCircleOutline,
  mdiLockOutline,
  mdiDotsVertical,
  mdiMagnify,
  mdiPencil,
  mdiCancel,
  mdiFilter,
  mdiReplay,
  mdiStop,
  mdiDiscord,
  mdiRefresh,
  mdiOpenInNew,
} from '@mdi/js'
import pick from 'lodash/pick'
import _cloneDeep from 'lodash/cloneDeep'
import {
  actionDetailsStatuses, StatusesColorMap, projectAccountStatuses, statusColors,
} from '@core/constants'
import DiscordApi from '@core/api/discord'
import RetryActionModal from '@/components/discord/action/RetryActionModal.vue'
import TableFooter from '@/components/TableFooter.vue'
import CopyTextBtn from '@/components/CopyTextBtn.vue'
import TableSearch from '@/components/TableSearch.vue'

export default {
  components: {
    RetryActionModal, TableFooter, CopyTextBtn, TableSearch,
  },
  data() {
    return {
      isExtEnabled: sessionStorage.getItem('discordExt'),
      icons: {
        mdiPencil,
        mdiDotsVertical,
        mdiChevronLeft,
        mdiCheckCircleOutline,
        mdiLockOutline,
        mdiMagnify,
        mdiCancel,
        mdiFilter,
        mdiReplay,
        mdiStop,
        mdiRefresh,
        mdiDiscord,
        mdiOpenInNew,
      },
      initialLoading: true,
      actions: [],
      loading: true,
      details: {},
      total: 50,
      showFilters: false,
      isProgress: true,
      countdown: 60,
      actionDetailsStatuses,
      StatusesColorMap,
      projectAccountStatuses,
      statusColors,
      oldStatus: null,
      oldSearch: null,
      tableOptions: {
        search: this.$route.query.search,
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
        status: this.$route.query.status ? this.$route.query.status : null,
      },
      pages: [50, 100, 200],
      accountHeaders: [
        {
          text: 'Username',
          value: 'username',
          filterable: true,
          sortable: false,
          align: 'start',
        },
        {
          text: 'Login',
          value: 'login',
          filterable: true,
          sortable: false,
          align: 'start',
        }, {
          text: 'Message',
          value: 'error',
        },
        {
          text: 'Status',
          value: 'status',
          filterable: true,
          sortable: false,
          align: 'center',
        },
      ],
    }
  },
  computed: {
    progress() {
      return this.countdown * 1.6
    },
    createdTime() {
      return this.details.created_at && this.details.created_at.replace('T', ' ').slice(0, this.details.created_at.length - 7)
    },
  },
  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value, oldValue) {
        console.log(value)
        const query = pick(value, ['page', 'status', 'search'])
        query.limit = value.itemsPerPage
        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        // FIXME - replace this sh*t
        if (value.status !== this.oldStatus
          || oldValue?.page !== value.page
          || oldValue?.itemsPerPage !== value.itemsPerPage
          || this.oldSearch !== value.search
        ) {
          this.fetchActionDetails(query)
        }
        this.oldStatus = value.status
        this.oldSearch = value.search
        this.$router.replace({ query }).catch(() => { })
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.fetchActionDetails()
    this.initialLoading = false
  },
  methods: {
    async fetchActionDetails(params = this.$route.query) {
      try {
        this.loading = true
        const { data } = await DiscordApi.Action.fetchAction(this.$route.params.id, params)
        // eslint-disable-next-line
        this.total = data.total;
        this.details = data
        this.loading = false
        if (this.isProgress) {
          this.restartRefreshInterval()
        }
      } catch (err) {
        console.err(err)
      }
    },
    openDiscordAccount(token) {
      if (this.isExtEnabled === 'true') {
        const event = new CustomEvent('discord-token', { detail: token })

        document.dispatchEvent(event)
      }
    },
    restartRefreshInterval() {
      this.countdown = 60
      clearInterval(this.intervalId)
      this.intervalId = setInterval(() => {
        this.countdown -= 1
        if (!this.countdown) {
          clearInterval(this.intervalId)
          this.fetchActionDetails()
        }
      }, 1000)
    },
    async tryStopAction(id) {
      try {
        const { data } = await DiscordApi.Action.stopAction({ id })
        this.details = { ...this.details, ...data }
        this.$message.success('Action stopped')
      } catch (err) {
        this.$message.error(err)
      }
    },
    openRetryActionModal(action) {
      this.$refs.retryActionModal.open(action)
    },
    checkFilterToggle() {
      const filters = _cloneDeep(this.tableOptions)
      const tableKeys = ['search', 'sortBy', 'sortDesc', 'page', 'itemsPerPage', 'groupBy', 'groupDesc', 'multiSort', 'mustSort']
      let filtersStatus = false
      Object.entries(filters).forEach(([key, value]) => {
        if (tableKeys.includes(key)) {
          delete filters[key]
        } else if (!!value !== false) {
          filtersStatus = true
        }
      })

      return filtersStatus
    },
    totalStatus(statuses) {
      return Object.keys(statuses).reduce((total, key) => total + statuses[key], 0)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel {
  &-header {
    padding: 0;
    padding-right: 16px;
  }
  &-content__wrap {
    padding: 0;
  }
}

::v-deep .v-skeleton-loader {
  &__heading {
    margin: 1rem 0;
  }

  &__card {
    margin: 1rem 0;
  }

  &__paragraph {
    margin: 1rem 0;
  }
}
.statuses{
  display: flex;
  margin-top: 1rem;
  .status{
    min-width: fit-content;
    &:last-child{
      background: red;
    }
  }
}
.v-tooltip__content {
  pointer-events: initial;
}
.disabled--tooltip{
  color: rgba(231, 227, 252, 0.3) !important;
}
</style>
